@import 'toggleTheme';
@import '../../styles/variables';
@import '../../styles/mixins';

.toggle {
  &.noDarkMode {
    --accent-color: #{$cerulean};
  }

  @include toggleTheme($cloud-grey, var(--accent-color), $white, $white, $white, $white, $cloud-grey);

  &:hover {
    outline: none;
  }
}

.hiddenInput {
  @include visuallyHidden;
}
