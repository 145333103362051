@import './styles/variables';
@import './styles/z-indices';
@import './styles/breakpoints';

.main {
  display: flex;
  flex-direction: column;
  height: 100%;
  color: var(--text-color);
}

.map {
  display: flex;
  flex-grow: 1;
  height: 100%;
  position: relative;
  overflow: hidden;
  background-color: var(--primary-color);

  @include breakpoint(screen-md, max) {
    flex-direction: column;
  }
}

.modal {
  padding: 2.5rem;
  h3 {
    margin-top: 0;
    font-size: $font-size-extra-large;
  }

  p {
    font-size: $font-size-medium;
  }
}

.mapBox {
  isolation: isolate;
  width: 100%;
  height: 100%;
}

:global {
  .mapboxgl-ctrl-group {
    margin: 20px 0 0 20px;
  }
}
