@import '../../styles/variables';
@import '../../styles/mixins';

$checkboxSize: 18px;
$checkboxPadding: 3px;
$checkboxBorderWidth: 2px;

.checkbox {
  display: flex;
  align-items: center;

  input {
    @include visuallyHidden;
  }

  white-space: nowrap;

  .checkboxButton {
    position: relative;
    box-sizing: border-box;
    display: inline-block;
    vertical-align: middle;
    width: $checkboxSize;
    height: $checkboxSize;
    background-color: $white;
    border: solid $checkboxBorderWidth $cloud-grey;
    flex-shrink: 0;

    &.checkboxChecked {
      background-color: $cerulean;
      border-color: transparent;

      > svg {
        position: absolute;
        left: -$checkboxBorderWidth;
        top: -$checkboxBorderWidth;
      }
    }
  }

  .checkboxLabel {
    display: inline-block;
    height: $checkboxSize;
    line-height: $checkboxSize;
    vertical-align: middle;
    @include overflowEllipsis();
  }

  &.disabled {
    .defaultCheckboxLabel {
      color: $darkgrey;
    }
  }

  &:not(.disabled) {
    .defaultCheckboxLabel {
      color: $navy-blue;
    }
  }
}

.checkbox:not(.disabled) {
  @include clickable();
}
