@import '../../styles/variables';
@import '../../styles/z-indices';
@import '../../styles/mixins';

.menu {
  box-sizing: border-box;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: $dropDownMenuIndex;
  margin: 0;
  padding: 0;
  max-width: 100%;
  width: 100%;
  list-style-type: none;
  max-height: 230px;
  overflow: auto;
  font-family: $font-family-regular;
  color: var(--text-color);
  background-color: $white;

  &:focus {
    outline: none;
  }

  @include inShipTrackerDarkMode {
    background-color: $navy-blue;
  }

  .item {
    padding: 8px 12px;
    font-size: $font-size-medium;
    color: $navy-blue;
    cursor: pointer;

    @include inShipTrackerDarkMode {
      background-color: $navy-blue;
      color: $white;
    }

    &.highlighted {
      background-color: $pale-blue;

      @include inShipTrackerDarkMode {
        background-color: $oxford-blue;
        color: $white;
      }
    }
  }

  &.open {
    border: 1px solid $lightblue;
  }
}
