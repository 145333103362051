@import '../styles/variables.scss';

@mixin generic-element($font-size, $margin-top, $line-height: 1em, $margin-bottom: 1em) {
  font-size: $font-size;
  line-height: $line-height;
  margin-top: $margin-top;
  margin-bottom: $margin-bottom;
}

.legalTermsPage {
  $contentWidth: 840px;

  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  background-color: var(--primary-color);
  color: var(--text-color);

  .legalTermsScrollable {
    flex: 1 1 auto;
    width: 100%;
    overflow-y: scroll;
  }

  .content {
    width: $contentWidth;
    max-width: 100%;
    padding: 3rem 1rem;
    margin: 0 auto;
    box-sizing: border-box;

    h1 {
      @include generic-element($font-size: 2em, $margin-top: 0);
    }
  }
}
.terms {
  h2 {
    @include generic-element($font-size: 1.5em, $margin-top: 1.5em);
  }

  h3 {
    @include generic-element($font-size: 1.2em, $margin-top: 1.5em);
  }

  h4 {
    @include generic-element($font-size: 1em, $margin-top: 1.5em, $line-height: 1em, $margin-bottom: 0);
  }

  p,
  li {
    @include generic-element($font-size: 1em, $margin-top: 1em, $line-height: 1.5em);

    font-family: $font-family-regular;
  }

  a {
    color: var(--accent-color);
  }
}
