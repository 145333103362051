@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/breakpoints';

$spinnerSize: 14px;

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;

  @include breakpoint(screen-md, max) {
    height: 100%;
  }

  &Text {
    font-family: $font-family-strong;
    padding-left: 5px;
  }

  .lightBackgroundSpinner {
    @include spinner($spinnerSize, 0);
  }

  .loaderInline {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: $spinnerSize;
    height: $spinnerSize;
  }
}

.basicDetails {
  margin: 0;
  display: flex;
  flex-direction: column;

  .label {
    padding-top: 0.5em;
    color: var(--accent-color);
    font-family: $font-family-strong;
    font-size: $font-size-tiny;
  }

  .notAvailable {
    color: var(--disabled-color);
  }

  .section {
    display: flex;
    flex-direction: column;
    .etaDate {
      display: flex;
      font-family: $font-family-strong;

      > .etaText + .etaText {
        margin-left: 0.25rem;
      }
    }
  }
}

.aisDetails {
  padding-bottom: 1rem;
}

.footer {
  display: flex;
  flex-direction: column;
  padding: 1.5rem 0 0.5rem;
  width: 100%;
}

.vesselDetails {
  &Title {
    font-size: $font-size-medium;
    font-family: $font-family-strong;
    margin: 1.5rem 0.75rem 1.5rem;
    display: flex;
    align-items: center;

    @include breakpoint(screen-md, max) {
      margin-right: 1rem;
      margin-left: 1rem;
    }

    .flag {
      width: 24px;
      height: auto;
      padding-right: 0.5rem;
    }
  }

  &Content {
    box-sizing: border-box;
    font-size: $font-size-regular;
    font-family: $font-family-regular;
    line-height: 1.2em;
    padding: 0 0.75rem;
    table-layout: fixed;
    width: 100%;

    @include breakpoint(screen-md, max) {
      padding-right: 1rem;
      padding-left: 1rem;
    }

    td {
      padding: 0;
    }

    tr.emptyEntry {
      height: 1em;
    }

    tr > td:first-child {
      display: flex;
      justify-content: space-between;
      .flag {
        display: inline-block;
        width: 16px;
        height: 12px;
        background-repeat: no-repeat;
        background-position: center;
      }
    }

    tr > td:last-child {
      font-family: $font-family-strong;
      width: 95px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:first-child {
      margin-top: 0;
    }

    &.rows tr {
      display: flex;
      flex-direction: column;

      > td:first-child {
        font-family: $font-family-strong;
      }

      > td:last-child {
        width: 100%;
      }
    }

    .arrivalDetails {
      .details {
        display: grid;
        grid-template-columns: 7.5rem 0.5rem 1fr;
        grid-row-gap: 0.5rem;

        .lockIcon {
          cursor: pointer;
          transform: translateY(-2px);
        }

        .value {
          font-family: $font-family-strong;
        }

        &.shiptracker,
        &.nextBerth {
          padding-bottom: 1.5rem;
        }

        &.portAuthority {
          padding-bottom: 1rem;
        }
      }

      .title {
        padding-bottom: 1rem;
        font-family: $font-family-strong;
        font-size: $font-size-medium;
        color: $cerulean;
      }
    }
  }

  &Photo {
    left: 0;
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: $light-greyish-orange;

    @include inShipTrackerDarkMode {
      background-color: var(--tertiary-color);
    }
  }

  &Picture {
    position: relative;
    aspect-ratio: 318/144;
    width: 100%;
    border-radius: 2px;
  }

  &CloseButton {
    @include clickable;
    position: absolute;
    height: 20px;
    width: 20px;
    top: 0.4rem;
    right: 0.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: $navy-blue;
    background-color: rgba($white, 0.5);

    @include inShipTrackerDarkMode {
      color: var(--accent-color);
      background-color: var(--primary-color);
    }

    @include breakpoint(screen-md, max) {
      top: 1rem;
      right: 1rem;
    }

    &Icon {
      font-size: 16px;
    }
  }
}

.portcallButton {
  @include button($cerulean, $white, $white, $navy-blue);

  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $font-size-small;
  margin: 0.75rem;
  text-align: center;
  font-family: $font-family-strong;

  .lockIcon {
    margin-left: 0.5rem;
    cursor: pointer;
  }

  &Disabled {
    background-color: transparent;
    border: solid 1px var(--disabled-color);
    color: var(--disabled-color);
    border-radius: 0;
    cursor: default;
    outline: none;
  }

  @include breakpoint(screen-md, max) {
    margin: auto 1rem 1rem;
  }
}

.addButton {
  @include button(transparent, var(--text-color), var(--tertiary-color), var(--text-color));

  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $font-size-small;
  margin: 0 0.75rem 0.75rem;
  text-align: center;
  font-family: $font-family-strong;
  border: solid 1px var(--text-color);
  border-radius: 2px;

  &Disabled:not(:disabled),
  &Disabled:hover:not(:disabled),
  &Disabled:focus:not(:disabled) {
    background-color: transparent;
    border-color: var(--disabled-color);
    color: var(--disabled-color);
    border-radius: 0;
    cursor: default;
    outline: none;
  }

  .icon {
    margin-right: 0.25rem;
    color: inherit;
    font-weight: 800;
    font-size: $font-size-medium;
    border-radius: 50%;

    &Disabled {
      color: var(--disabled-color);
    }
  }

  .lockIcon {
    margin-left: 0.5rem;
    cursor: pointer;
  }
}

.upgradeLabel {
  @include clickable;
  background-color: $mostly-pure-yellow;
  font-family: $font-family-strong;
  font-size: $font-size-tiny;

  @include inShipTrackerDarkMode {
    color: var(--primary-color);
  }
}
