@import '../../styles/variables';
@import '../../styles/mixins';

.input {
  box-sizing: border-box;
  appearance: none;
  width: 100%;
  background-repeat: no-repeat;
  background-position: right 1em top 50%;

  &.arrow {
    background-image: url(./icons/arrow.svg);
  }

  &.search {
    background-image: url(./icons/search.svg);
  }

  &.expand {
    background-image: url(./icons/expand_more.svg);

    &.isOpen {
      background-image: url(./icons/expand_less.svg);
    }
  }

  &.clear {
    @include clickable;
    background-size: 1.25rem;
    background-image: url(./icons/cancel.svg);
  }

  &.errors {
    color: $warning-color;
    border-color: $warning-color;
  }
}
