.ticker {
  position: relative;
  height: 1.2em; // Hardcoded for now. Until someone needs a different value.
  overflow: hidden;

  .inner {
    white-space: nowrap;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    min-width: 100%;
    text-decoration: inherit;

    transition: max-width 2s linear;

    &:not(:hover) {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:hover {
      // We need to set `max-width` to something bigger than
      // what will *ever* be needed. We need a fixed value,
      // because else `transition` won't work.
      max-width: 1000000px;
      overflow: visible;
      text-overflow: inherit;
    }
  }
}
