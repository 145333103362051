@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/z-indices';

.modalParent {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: $dropDownMenuIndex;
  padding: 0.75rem;
  box-sizing: border-box;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($black, 0.6);
}

.modal {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 32rem;
  max-height: 100vh;
  background-color: $white;
  z-index: $dropDownMenuIndex + 1;

  @include inShipTrackerDarkMode {
    background-color: var(--primary-color);
  }

  .content {
    padding: 2rem 2.5rem 2.5rem;

    .title {
      font-family: $font-family-strong;
      text-transform: uppercase;
      font-size: $font-size-extra-large;
      color: $cerulean;
      margin: 0 0 0.375em;
    }

    .text {
      font-family: $font-family-regular;
      font-size: $font-size-large;
      margin: 0 0 1em;
      white-space: pre-wrap;
    }
  }

  .header {
    display: flex;
    min-height: 2rem;
    align-items: stretch;
    justify-content: flex-end;

    .closeButton {
      @include clickable;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2rem;
      background-color: $cerulean;

      .closeButtonIcon {
        color: $white;
        font-size: 20px;
      }
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    border-top: 1px $lightgrey solid;
    padding: 1rem;

    @include inShipTrackerDarkMode {
      border-top-color: var(--border-color);
    }

    .button {
      font-family: $font-family-strong;
      font-size: $font-size-medium;
      padding: 1em 1.5em;
      border-radius: 3px;
    }

    .button + .button {
      margin-left: 1rem;
    }

    .cancelButton {
      @include button($navy-blue, $white, $navy-blue, $mostly-pure-yellow);

      @include inShipTrackerDarkMode {
        background-color: var(--secondary-color);
      }
    }

    .confirmButton {
      @include button($cerulean, $white, $navy-blue, $mostly-pure-yellow);

      .loadingStatus {
        display: flex;
        align-items: center;
      }

      $spinnerSize: 1em;

      .lightBackgroundSpinner {
        @include spinner($spinnerSize, 0, $white, transparent);
      }

      .loaderInline {
        position: relative;
        width: $spinnerSize;
        height: $spinnerSize;
        padding-right: 0.5rem;
      }
    }
  }
}
