@import '../styles/variables.scss';
@import '../styles/breakpoints.scss';
@import '../styles/mixins.scss';

.genericPage {
  $contentWidth: 840px;

  display: flex;
  width: 100%;
  min-height: 100%;
  flex-direction: column;
  background-color: var(--primary-color);
  color: var(--text-color);
  .scrollable {
    flex: 1 1 auto;
    width: 100%;
    overflow-y: scroll;
  }

  .content {
    width: $contentWidth;
    max-width: 100%;
    padding: 3rem 1rem;
    margin: 0 auto;
    box-sizing: border-box;
    background-color: var(--primary-color);

    &.formatTags {
      h2 {
        $font-size: 3em;
      }
      a {
        color: $cerulean;
      }
      p {
        white-space: pre-wrap;
        font-family: $font-family-regular;

        strong {
          font-family: $font-family-strong;

          a {
            font-family: $font-family-strong;
          }
        }
      }
    }
  }
}
