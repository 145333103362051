@import '../../styles/variables';
@import '../../styles/mixins';

.controlStrip {
  $backgroundColor: var(--tertiary-color);

  font-size: $font-size-medium;
  font-family: $font-family-regular;
  line-height: 1.2em;
  display: flex;
  align-items: stretch;
  color: var(--text-color);
  box-shadow: $ui-panel-shadow;
  border-radius: 2em;
  background-color: $backgroundColor;

  .entry {
    display: flex;
    align-items: center;

    & + .entry {
      border-left: 1px solid var(--border-color);
    }

    .button {
      @include clickable();

      display: flex;
      position: relative;
      align-items: center;
      padding: 1em 1.2em;
      background-color: $backgroundColor;

      &:hover {
        background-color: var(--dark-rollover-color);
      }

      &:focus {
        outline: none;
      }
    }

    &:last-child {
      .button {
        padding-right: 1.7em;
        border-top-right-radius: 2em;
        border-bottom-right-radius: 2em;
      }
    }

    &:first-child {
      .button {
        padding-left: 1.7em;
        border-top-left-radius: 2em;
        border-bottom-left-radius: 2em;
      }
    }
  }
}
