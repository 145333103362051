@import './variables';
@import './animations';
@import './shapes/round.scss';

@mixin overflowEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin clickable {
  cursor: pointer;
  user-select: none;
}

@mixin visuallyHidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

@mixin button($primary-color, $secondary-color, $tertiary-color, $quarternary-color) {
  background: $primary-color;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
  color: $secondary-color;
  padding: 0.75rem 1rem;

  &:not(:disabled) {
    @include clickable();
  }

  &:hover:not(:disabled) {
    background: $quarternary-color;
    color: $tertiary-color;
  }

  &:focus:not(:disabled) {
    outline: 1px solid $secondary-color;
  }
}

@mixin actionMenuItem($border-color, $font-size) {
  border-left: 1px $border-color solid;
  font-size: $font-size;

  &.active {
    color: $white;
    background-color: $lightblue;
  }
}

@mixin yellowButton {
  @include button(#ffd800, $navy-blue, $white, $navy-blue);
}

@mixin roundWithBorder($size, $borderColor) {
  @include round($size);
  border: 2px $borderColor solid;
  box-sizing: border-box;
}

@mixin spinner($size, $margin, $backgroundBorderColor: $mediumgrey, $foregroundBorderColor: $black) {
  @include roundWithBorder($size, $backgroundBorderColor);
  border-bottom-color: $foregroundBorderColor;
  animation: spinner-rotate 0.75s linear infinite;
  margin: $margin auto;
}

@mixin centerWrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@mixin overflowEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin spaced($spacing) {
  $margin-top: 0;
  $margin-right: 0;
  $margin-bottom: 0;
  $margin-left: 0;
  margin-top: $margin-top;
  margin-right: $margin-right;
  margin-bottom: $margin-bottom;
  margin-left: $margin-left;

  & > * {
    margin-left: $spacing / 2;
    margin-right: $spacing / 2;
  }
}

// CSS Triangles Mixin
@mixin css-triangle($direction, $color, $height, $width) {
  // Base styles for all triangles
  border-style: solid;
  height: 0;
  width: 0;
  // Cardinal Directions - can't be scalene this way
  @if ($direction == 'top') {
    border-color: transparent transparent $color transparent;
    border-width: 0 ($width/2) $height ($width/2);
  }
  @if ($direction == 'bottom') {
    border-color: $color transparent transparent transparent;
    border-width: $height ($width/2) 0 ($width/2);
  }
  @if ($direction == 'right') {
    border-color: transparent transparent transparent $color;
    border-width: ($height/2) 0 ($height/2) $width;
  }
  @if ($direction == 'left') {
    border-color: transparent $color transparent transparent;
    border-width: ($height/2) $width ($height/2) 0;
  }
  // Ordinal Directions - can be scalene this way!
  @if ($direction == 'top-left') {
    border-color: $color transparent transparent transparent;
    border-width: $height $width 0 0;
  }
  @if ($direction == 'top-right') {
    border-color: transparent $color transparent transparent;
    border-width: 0 $width $height 0;
  }
  @if ($direction == 'bottom-left') {
    border-color: transparent transparent transparent $color;
    border-width: $height 0 0 $width;
  }
  @if ($direction == 'bottom-right') {
    border-color: transparent transparent $color transparent;
    border-width: 0 0 $height $width;
  }
}

@mixin inShipTrackerDarkMode {
  :global(.ship-tracker-theme.ship-tracker-theme--dark) & {
    @content;
  }
}
