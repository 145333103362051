@import '../styles/variables.scss';
@import '../styles/z-indices.scss';
@import '../styles/mixins.scss';
@import '../Header/variables.scss';

$header-font-size: 20px;
$padding: $header-font-size;
$quick-ship-search-width: 320px;
$toggle-size: $header-height;
$quick-ship-search-focus-width: $quick-ship-search-width + $toggle-size;

.toggleWrapper {
  position: relative;
  width: $toggle-size;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  &:focus {
    outline: none;
  }
}

.search {
  flex: 1 1 0;
  overflow: hidden;
  width: 0;

  &.isActive {
    width: $quick-ship-search-width;

    .searchListing {
      border: solid 1px var(--border-color);
    }
  }

  transition: width 0.5s ease-out;

  .input {
    $font-size: $header-font-size;
    $padding: ($header-height - $font-size) / 2;
    font-family: $font-family-regular;
    color: var(--text-color);
    background-color: transparent;
    width: 100%;
    height: $font-size;
    margin: 0;
    border-width: 0;
    outline: none;
  }

  .searchListing {
    position: absolute;
    z-index: $dropDownMenuIndex;
    left: 0;
    width: 100%;
    top: $header-height;
    max-height: calc(100vh - #{$header-height});
    background-color: var(--primary-color);
    color: var(--text-color);
    overflow-x: hidden;
    overflow-y: auto;

    .loader {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: $padding;
    }

    .notice {
      box-sizing: border-box;
      padding: $padding;
      font-family: $font-family-regular;
      font-size: 12px;
      font-style: italic;
    }

    .suggestion {
      $suggestion-height: 57px;
      $padding: $suggestion-height - 2 * $header-font-size;
      cursor: pointer;
      padding: $padding;

      .name,
      .ship-id {
        font-size: $font-size-medium;
        line-height: $font-size-medium;
        height: $font-size-medium;
        overflow: hidden;
        @include overflowEllipsis;
      }

      .name {
        font-family: $font-family-strong;
      }
      .ship-ids {
        font-family: $font-family-regular;
        @include spaced(15px);
      }
      .ship-id {
        .ship-id-label {
          text-transform: uppercase;
          opacity: 0.5;
        }
      }

      &:hover,
      &.suggestion.selected {
        background-color: var(--dark-rollover-color);
      }
    }
  }
}

.ship-ids {
  font-family: $font-family-regular;

  .ship-id {
    margin-right: 0.5em;
    font-size: $font-size-medium;
    line-height: $font-size-medium;
    height: $font-size-medium;
    overflow: hidden;
    @include overflowEllipsis;

    .ship-id-label {
      text-transform: uppercase;
      opacity: 0.5;
    }
  }
}

.closeButton {
  @include clickable();
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: $accountMenuIndex;
}

.closeButtonIcon {
  font-size: 25px;
  font-weight: bold;
  color: var(--text-color);
}

.mobileSearch {
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  color: var(--text-color);
  background-color: var(--primary-color);
  z-index: $mobileSearchIndex;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  .title,
  .inputWrapper {
    padding: 0 1rem;
  }

  .comboBox {
    position: relative;
  }

  .input {
    box-sizing: border-box;
    font-size: $font-size-medium;
    font-family: $font-family-regular;
    box-sizing: border-box;
    padding: 0.75em 1em;
    border-radius: 1px;
    border: solid 1px $cloud-grey;
    background-color: $white;
    width: 100%;
    outline: none;
  }

  .searchListing {
    position: absolute;
    z-index: $dropDownMenuIndex;
    left: 0;
    width: 100%;
    top: $header-height;
    max-height: calc(100vh - #{$header-height});
    overflow-x: hidden;
    overflow-y: auto;

    .loader {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: $padding;
    }

    .notice {
      box-sizing: border-box;
      padding: $padding;
      font-family: $font-family-regular;
      font-size: 12px;
      font-style: italic;
    }

    .suggestion {
      $suggestion-height: 57px;
      $padding: $suggestion-height - 2 * $header-font-size;
      cursor: pointer;
      padding: $padding;

      .name,
      .ship-id {
        font-size: $font-size-large;
        line-height: $font-size-large;
        height: $font-size-large;
        overflow: hidden;
        @include overflowEllipsis;
      }

      .name {
        font-family: $font-family-strong;
      }
      .ship-ids {
        font-family: $font-family-regular;
        & + .ship-ids {
          padding-left: 1rem;
        }
      }
      .ship-id {
        .ship-id-label {
          text-transform: uppercase;
          opacity: 0.5;
        }
      }

      &:hover,
      &.suggestion.selected {
        background-color: var(--dark-rollover-color);
      }
    }
  }
}
