@import '../../styles/variables';
@import '../variables';
@import '../../styles/breakpoints';
@import '../../styles/z-indices';

.menuNavigation {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: $header-height;
  bottom: 0;
  left: 0;
  height: calc(100% - #{$header-height});
  width: $menu-width;
  background-color: var(--primary-color);
  border-top: solid 1px var(--border-color);
  border-right: solid 1px var(--border-color);
  z-index: $accountMenuIndex;
  transform: translateX(-100%);
  transition: 0.2s transform;
  box-sizing: border-box;

  @include breakpoint(screen-md, max) {
    width: 100%;
    border-right: none;
  }

  &Open {
    transform: translateX(0);
  }

  &Overlay {
    position: absolute;
    top: $header-height;
    left: 0;
    width: 100%;
    height: calc(100% - #{$header-height});
    z-index: $accountMenuOverlayIndex;
    background-color: $shadow-color;
  }

  .entry {
    border-bottom: solid 1px var(--border-color);
    padding: 0;

    a {
      color: inherit;
      cursor: pointer;

      .text {
        pointer-events: none;
      }
    }

    button {
      cursor: pointer;
    }

    .inner,
    &.inner {
      display: flex;
      align-items: center;
      padding: 1.25rem 0.75rem;
      color: inherit;
      text-decoration: none;
      box-sizing: border-box;
    }

    &Small {
      .text {
        font-size: $font-size-regular;
      }
      .inner,
      &.inner {
        padding: 0.5rem 0.75rem;
      }
    }
    &Inactive {
      background-color: var(--tertiary-color);
      .text {
        opacity: 0.6;
      }
    }
  }

  .section {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 0;

    &Top {
      margin-bottom: auto;
    }

    &Bottom {
      margin-top: auto;

      .entry {
        border-top: solid 1px var(--border-color);
        border-bottom: none;
      }
    }
  }

  .text {
    font-size: $font-size-medium;
    font-family: $font-family-strong;
    user-select: none;
    margin-right: 1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 2;
  }

  .label {
    margin-right: 0.5rem;
    background-color: $mostly-pure-yellow;
    font-family: $font-family-strong;
    color: $navy-blue;
    font-size: 11px;
    padding: 0.1em 0.3em;
    border-radius: 1px;

    &.hidden {
      visibility: hidden;
    }
  }

  .cartIcon {
    margin-left: auto;
    margin-right: 0.5rem;
    fill: var(--text-color);
  }
}
