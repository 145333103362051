@import '../../styles/variables';
@import '../../styles/spaced';
@import '../../styles/spacing';
@import '../../styles/mixins.scss';

$radioSize: 18px;
$radioButtonBorderWidth: 2px;
$checkedRadioSize: $radioSize - ($radioButtonBorderWidth * 4);

.radioButtonContainer {
  display: flex;
  align-items: center;
}

.label {
  margin-left: spacing(2);
  padding: spacing(1) 0;
  transform: translateY(1px);
  @include clickable();
}

.radioButton {
  white-space: nowrap;
  box-sizing: border-box;
  width: $radioSize;
  height: $radioSize;
  display: inline-block;
  border-radius: $radioSize / 2;
  background-color: $white;
  border: solid 2px $cloud-grey;
}

.radioInput {
  @include visuallyHidden;
  &:checked ~ .radioButton {
    border: solid 2px $cerulean;
    position: relative;

    &:before {
      box-sizing: border-box;
      position: absolute;
      height: $checkedRadioSize;
      width: $checkedRadioSize;
      left: $radioButtonBorderWidth;
      top: $radioButtonBorderWidth;
      border-radius: $checkedRadioSize / 2;
      content: '';
      display: block;
      background-color: $cerulean;
    }
  }
}
