@import '../../styles/mixins.scss';
@import '../../styles/z-indices.scss';
@import '../variables.scss';

.menu {
  height: $header-height;
  display: flex;
  justify-content: space-between;

  [role='combobox'] {
    height: 100%;
  }

  .menuItem {
    @include clickable();
    @include actionMenuItem(var(--border-color), $header-font-size);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    min-width: 50px;
    box-sizing: border-box;

    &.logoItem {
      padding-left: 15px;

      .logo {
        fill: var(--text-color);
      }
    }

    &.toggleItem {
      flex-grow: 1;
      justify-content: flex-start;
      padding: 0 20px;

      .menuText {
        margin-right: 1rem;
      }
    }

    &.helpItem {
      padding: 0 20px;
    }

    &.hamburgerItem {
      border-right: solid 1px var(--border-color);

      .hamburgerStyle {
        > span {
          background: var(--text-color);
        }
      }
    }

    .menuText {
      font-size: $font-size-regular;
      font-family: $font-family-strong;
      text-transform: uppercase;
      pointer-events: none;
    }

    &.login,
    &.myAccount {
      font-size: $font-size-medium;
      padding: 0 15px;

      .accountIcon {
        fill: var(--text-color);
      }

      &:hover {
        color: $white;
        background-color: $grape-color;

        @include inShipTrackerDarkMode {
          background-color: var(--tertiary-color);
        }

        .accountIcon {
          fill: $white;
        }
      }
    }

    &.login .action {
      padding-right: 10px;
    }

    &.myAccount {
      display: flex;
      justify-content: space-between;

      .action {
        padding: 0 5px;
      }

      .label {
        color: $navy-blue;
        background: $mostly-pure-yellow;
        border-radius: 2px;
        font-size: $font-size-small;
        padding: 2px 4px;
      }

      .accountStatus {
        position: absolute;
        right: 25px;
        top: 5px;
      }

      .menuHandle {
        font-size: 20px;
      }

      .account {
        &Menu {
          padding: 0;
          margin: 0;
          list-style: none;
          max-width: 200px;

          @include inShipTrackerDarkMode {
            border: none;
          }

          &Item {
            display: flex;
            box-sizing: border-box;
            padding: 15px;
            width: 100%;
            min-height: 65px;
            align-items: center;
            border-bottom: solid 1px var(--border-color);

            &:last-child {
              border-bottom: none;
            }

            &:hover .action {
              text-decoration: underline;
            }

            .icon {
              color: $cerulean;
            }
          }
        }
      }
    }
  }

  .left,
  .right {
    display: flex;
  }

  .left {
    flex-grow: 1;
    justify-content: space-between;

    .leftItems {
      display: flex;
      align-items: center;
    }

    .buyApiCredits {
      @include clickable;
      padding-right: 1rem;

      .menuText {
        display: flex;
        align-items: center;
        text-transform: none;
      }

      .label {
        background-color: $mostly-pure-yellow;
        color: $navy-blue;
        font-size: $font-size-small;
        padding: 0.1em 0.3em;
        border-radius: 1px;
        text-transform: uppercase;
        margin-left: 0.5rem;
      }

      .cta {
        padding: 0 0.5em;
      }

      .icon {
        fill: var(--text-color);
        transform: translateY(-1px);
      }
    }

    .menuItem {
      border-left: none;
    }
  }

  .right {
    align-items: center;
    justify-content: flex-end;

    &:first-child {
      margin-left: auto;
    }

    &.widthToVesselListing {
      min-width: $width-vessel-listing;
    }
  }
}
