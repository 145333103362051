/* https://fkhadra.github.io/react-toastify/how-to-style */
@import '../../styles/variables.scss';
@import '../../styles/z-indices.scss';
@import '../../styles/breakpoints.scss';

.Toastify__toast {
  padding: 0;
  margin: 0;
  min-height: initial;
  display: flex;
  padding: 1em;
}

.Toastify__toast-container {
  width: 560px;
  max-width: 100%;
  position: fixed;
  z-index: $toastIndex;
}
/** Used to define the position of the ToastContainer **/

.Toastify__toast-container--bottom-center {
  bottom: 1em;
  left: 50%;
  transform: translateX(-50%);

  @include breakpoint(screen-md, max) {
    bottom: 0;
  }
}

.Toastify__progress-bar {
  height: 2px;
}

.Toastify__toast-body {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Toastify__close-button {
  align-self: center;
  color: $lightgrey;
}

.Toastify__close-button:hover > svg,
.Toastify__close-button:focus > svg {
  fill: $navy-blue;
}

.Toastify__toast--info {
  background-color: $white;

  .Toastify__progress-bar {
    background-color: $cerulean;
  }
}

.Toastify__toast--error {
  background-color: $white;

  .Toastify__progress-bar {
    background-color: $warning-color;
  }
}

.Toastify__toast--success {
  background-color: $white;

  .Toastify__progress-bar {
    background-color: $moderate-lime-green;
  }
}
