@import '../../../../styles/variables.scss';

.countriesDropdown {
  position: relative;
  .label {
    display: flex;
    flex-direction: column;
  }

  .input {
    margin-top: 5px;
    font-size: $font-size-medium;
    font-family: $font-family-regular;
    box-sizing: border-box;
    padding: 0.75em 1em;
    border-radius: 1px;
    border: solid 1px $cloud-grey;
    color: $navy-blue;
    background-color: $white;

    &:focus {
      outline: none;
    }
  }
}
