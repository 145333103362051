@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/z-indices';
@import '../../styles/breakpoints';

$dropper-background: var(--secondary-color);
$dropper-arrow-size: 5px;
$base-spacing: 0.25rem;
$sm-min: 30rem;

.dropoutDropper {
  box-sizing: border-box;
  position: absolute;
  color: var(--text-color);
  z-index: $dropDownMenuIndex + 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  filter: drop-shadow(0 0 1px var(--border-color));

  &.horizontal {
    flex-direction: row;
  }

  .triangle {
    margin: auto;
  }

  &:not(.horizontal) {
    .triangle {
      &.offsetLeft {
        transform: translateX(-50%);
      }

      &.offsetRight {
        transform: translateX(50%);
      }
    }
  }

  &.horizontal {
    .triangle {
      &.offsetLeft {
        transform: translateY(-50%);
      }

      &.offsetRight {
        transform: translateY(50%);
      }
    }
  }

  &:not(.horizontal) {
    .triangle.top {
      @include css-triangle(top, $dropper-background, $dropper-arrow-size, $dropper-arrow-size * 2);
    }

    .triangle.bottom {
      @include css-triangle(bottom, $dropper-background, $dropper-arrow-size, $dropper-arrow-size * 2);
    }

    &.top {
      top: calc(100% - #{$dropper-arrow-size});
      &.triangleSpace {
        top: 100%;
      }
      &.asPopupOnMobile {
        @include breakpoint(screen-md, max) {
          top: 0;
        }
      }
    }

    &.bottom {
      flex-direction: column-reverse;
      bottom: calc(100% - #{$dropper-arrow-size});
      &.triangleSpace {
        bottom: 100%;
      }
    }

    &.left {
      left: 0;
    }

    &.right {
      right: 0;
    }

    &.center {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &.horizontal {
    .triangle.top {
      @include css-triangle(left, $dropper-background, $dropper-arrow-size * 2, $dropper-arrow-size);
    }

    .triangle.bottom {
      @include css-triangle(right, $dropper-background, $dropper-arrow-size * 2, $dropper-arrow-size);
    }

    &.top {
      top: 0;
    }

    &.bottom {
      bottom: 0;
    }

    &.left {
      left: calc(100% - #{$dropper-arrow-size});

      &.triangleSpace {
        left: 100%;
      }
    }

    &.right {
      flex-direction: row-reverse;
      right: calc(100% - #{$dropper-arrow-size});

      &.triangleSpace {
        right: 100%;
      }
    }
  }

  &.asPopupOnMobile {
    @include breakpoint(screen-md, max) {
      position: fixed;
      top: unset;
      bottom: 0;
      right: 0;
      left: 0;
    }

    .content {
      @include breakpoint(screen-md, max) {
        max-width: 100%;
        box-sizing: border-box;
      }
    }

    .triangle {
      @include breakpoint(screen-md, max) {
        display: none;
      }
    }
  }
}

.content {
  flex-grow: 1;
  padding: $base-spacing * 5;
  width: 100vw;
  background: $dropper-background;

  @media screen and (min-width: $sm-min) {
    max-width: 12rem;
    min-width: max-content;
  }
}
