@import '../styles/variables.scss';
@import '../styles/mixins.scss';

.header {
  background-color: var(--primary-color);
  border-bottom: solid 1px $mediumgrey;

  @include inShipTrackerDarkMode {
    border-bottom-color: var(--border-color);
  }
}
