@import '../../styles/variables.scss';

.button {
  display: block;
  position: relative;
  background-color: $mostly-pure-yellow;
  border: solid 1px $mostly-pure-yellow;
  padding: 0.75rem 2rem;
  font-size: $font-size-medium;
  font-family: $font-family-strong;
  color: $navy-blue;
  cursor: pointer;

  &Disabled {
    background-color: $light-grey;
    border-color: $light-grey;
    pointer-events: none;
    color: $darkgrey;
  }
  &Outlined {
    background-color: transparent;
    color: var(--text-color);
    border: solid 1px var(--text-color);
    border-radius: 4px;
  }
  &ForceLight {
    color: $white;
    border-color: $white;
  }
}
