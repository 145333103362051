$black: black;
$white: white;

$alto: #dedede;
$navy-blue: #001f4b;
$deep-blue: #0a4691;
$lochmara: #077ab7;
$san-juan: #2a4367;
$black-squeeze: #e0eef4;
$mediumblue: #0087c6;
$cerulean: #0693ca;
$lightblue: #429ad1;
$sea-green: #007e91;
$lightgrey: #ddd;
$mediumgrey: #cccccc;
$darkgrey: #9b9b9b;
$broken-white: #f8f8f8;
$metal-grey: #696e7d;
$grape-color: #643c73;
$warning-color: #db392a;
$shadow-color: rgba(0, 0, 0, 0.5);
// https://www.colorhexa.com/e6007e
$mostly-pure-pink: #e6007e;
$mostly-pure-yellow: #ffd800;
$sahara: #a1a10c;
$pale-blue: #bde4ff;
$dark-greyish-orange: #b3ada7;
$cloud-grey: #ccc8c5;
$light-grey: #d8d8d8;
$pale-sky: #696e7d;
$greyish-orange: #c0bbb5;
$very-light-grey: #efefef;
$dark-greyish-blue: #708090;
$light-greyish-orange: #e6e4e2;
$light-greyish-blue: #e0eef4;
$moderate-lime-green: #46be41;
// https://chir.ag/projects/name-that-color
$sandal: #ad9475;
$blue-lagoon: #007e91;
$blue-bayoux: #536785;
$catalina-blue: #073670;
$midnight: #001129;
$black-squeeze: #f0f7fa;
$oxford-blue: #334053;
$concrete: #f2f2f2;
$wisteria: #9e71af;
$polar: #e8f5fa;
$mine-shaft: #222222;

$peppermint: #d1efd0;
//==================
//
// Typography
//==================
$font-family-strong: 'NLRTM-Bold', 'Trebuchet MS', Arial, sans-serif;
$font-family-regular: 'NLRTM-Regular', 'Trebuchet MS', Arial, sans-serif;

$font-size-tiny: 10px;
$font-size-small: 11px;
$font-size-regular: 12px;
$font-size-medium: 14px;
$font-size-large: 16px;
$font-size-18: 18px;
$font-size-20: 20px;
$font-size-extra-large: 24px;
$font-size-extra-extra-large: 26px;
$font-size-extra-extra-extra-large: 30px;
$font-size-34: 34px;
$font-size-giant: 60px;

//==================
// Theme Colors
//==================
$theme-background-color: #ececec;

//==================
// Drawer
//==================
$drawerHeaderHeight: 55px;

$ui-panel-shadow: 0 2px 4px 0 rgba($black, 0.5);

$width-vessel-listing: 400px;
