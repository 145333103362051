.mapLayers {
  position: absolute;
  left: 20px;
  top: 20px;

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    appearance: none;
    filter: drop-shadow(0 0 1px var(--border-color));
    background-color: var(--tertiary-color);
    border-radius: 24px;
    margin-right: 1rem;
    cursor: pointer;

    .icon {
      fill: var(--text-color);
    }
  }

  .list {
    list-style: none;
    margin: 0.5rem 0 0;
    padding: 0;

    .item {
      margin: 0.5rem 0 0.25rem;
    }
  }
}
