@import '../../styles/mixins';

.placeholder {
  object-fit: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: $font-family-strong;
  font-size: 9px;
  color: $dark-greyish-orange;
}
