@import '../../styles/variables';
@import '../../styles/z-indices';
@import '../../styles/mixins';

.mapControlStrip {
  $offset: 20px;
  position: absolute;
  left: $offset;
  bottom: $offset * 2;
  z-index: $mapControlStripIndex;

  a:link,
  a:visited {
    color: $navy-blue;
    @include inShipTrackerDarkMode {
      color: var(--text-color);
    }
    text-decoration: none;
  }
}

.icon {
  color: $cerulean;
  font-size: 1.45em;
  padding-right: 0.25em;
}

.placeIcon {
  $size: 1.25rem;
  width: $size;
  height: $size;
  background-color: $cerulean;
  mask-image: url('./icons/place-24px.svg');
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100%;
}

.checkboxLabel {
  padding-left: 0.5em;
  vertical-align: middle;
}

.calculated {
  font-family: $font-family-strong;
}

.signup {
  font-family: $font-family-strong;
}

.label {
  font-family: $font-family-strong;
  border-radius: 2px;
  font-size: $font-size-small;
  padding: 2px 4px;
  margin-left: 1em;
}

.active {
  color: $white;
  background-color: $cerulean;
}

.upgrade {
  @include clickable;
  background-color: $mostly-pure-yellow;
  font-family: $font-family-strong;

  @include inShipTrackerDarkMode {
    color: var(--primary-color);
  }
}

.menu {
  padding: 2em 1em;
  max-width: 16rem;
  min-width: auto;
  box-sizing: border-box;
  background: var(--tertiary-color);

  .title {
    display: inline;
    font-size: $font-size-medium;
    font-family: $font-family-strong;
    margin-bottom: 0.5em;
  }

  .text {
    font-size: $font-size-regular;
    white-space: pre-wrap;
    line-height: 1.1em;

    a:link {
      text-decoration: underline;
    }

    &.signupText {
      padding-bottom: 1em;
    }

    &.loginText {
      text-align: center;
    }

    b {
      font-family: $font-family-strong;
    }
  }

  .signupButton {
    display: inline-block;
    box-sizing: border-box;
    text-align: center;
    font-family: $font-family-strong;
    width: 100%;

    @include button($mostly-pure-yellow, $navy-blue, $white, $navy-blue);

    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-top: 1.25rem;
  }

  a.signupButton {
    @include inShipTrackerDarkMode {
      color: var(--primary-color);
    }
  }
}

.closeButton {
  @include clickable;
  position: absolute;
  line-height: 1.25rem;
  top: 0.4rem;
  right: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $cerulean;

  @include inShipTrackerDarkMode {
    color: var(--accent-color);
  }

  &Icon {
    font-size: 20px;
  }
}

.triangleBottom {
  $dropper-arrow-size: 5px;
  @include css-triangle(bottom, var(--tertiary-color), $dropper-arrow-size, $dropper-arrow-size * 2);
}
