@import '../../styles/variables';
@import '../../styles/z-indices';
@import '../../styles/mixins';
@import '../../styles/breakpoints';

.legendBox {
  position: absolute;
  min-width: 180px;
  right: 20px;
  bottom: 40px;
  border-radius: 6px;
  background-color: var(--primary-color);
  box-shadow: $ui-panel-shadow;
  background-color: var(--tertiary-color);

  &WithListOffset {
    right: 20px + $width-vessel-listing;
  }

  @include breakpoint(screen-xl, max) {
    top: 20px;
    bottom: unset;
  }

  .entry {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 1em 1.2em;

    .icon {
      width: 15px;
      height: 24px;
      margin-right: 1rem;

      svg {
        width: 15px;
        height: 24px;
      }
    }

    .text {
      font-size: $font-size-medium;
      font-family: $font-family-regular;
      line-height: 1.2em;
      color: var(--text-color);
    }
  }
}
