@import '../../styles/variables.scss';
@import '../../styles/spacing.scss';

$overlaySpacing: spacing(3);

.fullScreenOverlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  color: $white;
  background: var(--primary-color);
}

.fullScreenOverlayTopBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--border-color);
}

.fullScreenOverlayTitle {
  margin: 0;
  padding: 0 $overlaySpacing;
  font-size: $font-size-regular;
  font-family: $font-family-strong;
  text-transform: uppercase;
  color: var(--text-color);
}

.fullScreenOverlayCloseButton {
  border-left: 1px solid var(--border-color);
  padding: $overlaySpacing;
  border-radius: 0;
  font-weight: normal;
  background: none;
  color: var(--text-color);
  cursor: pointer;

  &:focus,
  &:hover {
    background: var(--accent-color);
    color: $white;
  }
}

.fullScreenOverlayCloseButtonText {
  font-size: $font-size-regular;
  font-family: $font-family-regular;
}

.fullScreenOverlayCloseButtonIcon {
  margin-left: spacing(1);
  font-size: 16px;
  display: inline-block;
  vertical-align: middle;
  fill: var(--text-color);
}

.fullScreenOverlayContent {
  overflow: auto;
  padding: $overlaySpacing * 3;
}
