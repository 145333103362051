// collection of all zIndexes throughout the application
// General
$minusOneIndex: -1;

// Account Menu
$accountMenuIndex: 100;
$accountMenuOverlayIndex: 99;

// Mobile Search
$mobileSearchIndex: 110;

// DropdownMenu
$dropDownMenuIndex: 99;

// Toast
$toastIndex: 100;

// ControlStrip
$mapControlStripIndex: 3;

// Drawer
$drawerOverlayIndex: 128;
$drawerIndex: 129;

$cookieBarIndex: 200;
