@import '../../styles/variables.scss';

.hamburgerMenuWrapper {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  outline: none;

  .hamburgerMenu {
    // based on
    // https://codepen.io/designcouch/pen/Atyop
    $size: 2px;
    $height: 7 * $size;

    width: 20px;
    height: $height;
    position: relative;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    pointer-events: none;

    span {
      display: block;
      position: absolute;
      height: $size;
      width: 100%;
      background: $white;
      border-radius: $size;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: 0.25s ease-in-out;
    }

    span:nth-child(1) {
      top: 0px;
      transform-origin: left center;
    }

    span:nth-child(2) {
      top: 3 * $size;
      transform-origin: left center;
    }

    span:nth-child(3) {
      top: 6 * $size;
      transform-origin: left center;
    }

    &.open span:nth-child(1) {
      transform: rotate(45deg);
      top: 0;
      left: 4px;
    }

    &.open span:nth-child(2) {
      width: 0%;
      opacity: 0;
    }

    &.open span:nth-child(3) {
      transform: rotate(-45deg);
      top: $height;
      left: 4px;
    }
  }
}
