@import '../styles/variables.scss';
@import '../styles/spacing.scss';
@import '../styles/mixins';

.fullVesselDetails {
  display: flex;
  color: var(--text-color);
}

.imageContainer {
  margin-right: spacing(8);
  flex: 0 1 25%;
  min-width: 240px;
  max-width: 380px;

  .placeholder {
    aspect-ratio: 3/2;
    background-color: $light-greyish-orange;

    @include inShipTrackerDarkMode {
      background-color: var(--tertiary-color);
    }
  }
}

.image {
  display: block;
  max-width: 100%;
  height: auto;
}

.detailsListContainer {
  flex: 1 0 min-content;
  max-width: 70rem;
}

.shipName {
  margin: 0 0 spacing(4);
  font-size: 30px;
}

.groupHeading {
  margin: spacing(4) 0 spacing(2);
  font-family: $font-family-strong;
  font-size: $font-size-large;
  color: var(--accent-color);
}

.detailsList {
  margin-bottom: spacing(8);
  columns: 2;
  gap: spacing(8);
  font-size: $font-size-medium;

  .group {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: spacing(2) 0;
  }

  .defLabel,
  .defValue {
    margin: 0;
    padding: 0;
  }

  .defLabel {
    break-inside: avoid;
    padding-right: spacing(2);
    flex: 0 0 40%;
    font-family: $font-family-regular;
  }

  .defValue {
    position: relative;
    flex: 1 0 0;
    padding-left: spacing(2);
    max-width: 100%;
    font-family: $font-family-strong;
    white-space: normal;

    .lockIcon {
      cursor: pointer;
      margin-left: 0.25rem;
    }

    &::before {
      content: ': ';
      position: absolute;
      left: 0;
    }
  }
}
