@import './NLRTM-font-family';
@import './variables';

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
}

*:not(svg, svg *) {
  box-sizing: border-box;
}

/**
 * Firefox specific rule
 */

@-moz-document url-prefix() {
  body {
    font-weight: lighter !important;
  }
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;

  font-family: $font-family-strong;
  font-size: $font-size-large;
  font-weight: normal;
}

body {
  overscroll-behavior: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-strong;
  font-weight: normal;
}

input,
textarea,
select,
button {
  font-weight: normal;
  background: transparent;
  border: none;
}

strong,
th {
  font-weight: normal;
  font-family: $font-family-strong;
}

:global(#root) {
  height: 100%;
}

:global(.mapboxgl-canvas) {
  outline: none;
}
