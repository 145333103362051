@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/breakpoints';
@import '../../styles/functions';

$dashed-border: 1px dashed var(--text-color);
$detailsMarkerWidth: 320px;

:export {
  DETAILS_MARKER_WIDTH: strip-unit($detailsMarkerWidth);
}

.marker {
  box-sizing: border-box;
  min-width: 12rem;
  font-size: $font-size-regular;
  line-height: 1.2;
  padding: 10px;
  border-radius: 4px;
  border: $dashed-border;
  background-color: var(--tertiary-color);
  transform: translateY(calc(-100% - 15px));
  pointer-events: none;
}

.pointFreeMarker {
  pointer-events: none;
}

.markerHeader {
  padding: 0;
  margin: 0;
  font-family: $font-family-strong;
}

.portMarker {
  @include clickable();
}

.detailsMarker {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: $detailsMarkerWidth;
  background-color: $very-light-grey;
  font-size: $font-size-medium;
  line-height: 1.2;
  font-family: $font-family-regular;
  transform: translate(15px, -50%);
  border: $dashed-border;

  &.visibilityHidden {
    visibility: hidden;
  }

  @include inShipTrackerDarkMode {
    background-color: var(--tertiary-color);
  }

  @include breakpoint(screen-md, max) {
    width: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    transform: none;
  }
}
