@import '../../../styles/variables';
@import '../../../styles/spacing';
@import '../../../styles/mixins';
@import '../../../styles/spaced';
@import '../../../styles/breakpoints';

$filter-dropout-top-offset: 209px;

.vesselListing {
  $small-button-width: 55px;
  $transition-duration: 0.2s;

  width: $width-vessel-listing;
  max-width: 100%;
  height: 100%;
  position: relative;
  flex-shrink: 0;
  transition: width $transition-duration, height $transition-duration;

  @include breakpoint(screen-md, max) {
    width: 100%;
    height: 65%;
    margin-top: auto;
    border-top: solid 1px var(--border-color);
  }

  &Hidden {
    width: 0;

    @include breakpoint(screen-md, max) {
      width: 100%;
      height: 0;
    }
  }

  .visibilityButton {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    right: 100%;
    width: 2.25rem;
    height: 2.25rem;
    background-color: $cerulean;
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
    cursor: pointer;

    @include breakpoint(screen-md, max) {
      top: unset;
      right: 0;
      left: 0;
      bottom: 100%;
      margin: 0 auto;
      border-top-right-radius: 50%;
      border-bottom-left-radius: 0;
    }

    .visibilityIcon {
      display: block;
      color: $cerulean;
      background-color: $white;
      border-radius: 50%;
      font-size: 1.5rem;
      font-weight: 800;
      box-shadow: 0 0 2px 1px rgba($black, 0.1);
      transition: transform 0.2s;

      @include breakpoint(screen-md, max) {
        transform: rotate(90deg);
      }

      &Rotated {
        transform: rotate(180deg);

        @include breakpoint(screen-md, max) {
          transform: rotate(270deg);
        }
      }
    }
  }

  .vesselListingContent {
    position: absolute;
    left: 0;
    top: 0;
    width: $width-vessel-listing;
    height: 100%;
    background: var(--primary-color);
    display: flex;
    flex-direction: column;
    box-shadow: 0 2px 4px 0 rgba(174, 174, 174, 0.5);

    @include inShipTrackerDarkMode {
      box-shadow: none;
      border-left: solid 1px var(--border-color);
    }

    @include breakpoint(screen-md, max) {
      width: 100%;
    }
  }

  header {
    padding: 1.25rem;
    color: $cerulean;
    border-bottom: solid 1px var(--border-color);

    .title {
      @include overflowEllipsis();
      color: var(--text-color);
      margin: 0;
      font-size: 1.25em;
    }
  }

  .actions {
    $border-color: $blue-bayoux;

    display: flex;
    height: 40px;
    position: relative;
    align-items: center;
    background: $navy-blue;
    color: $white;
    flex-shrink: 0;

    .filtersButtonWrapper {
      flex-grow: 1;
      height: 100%;

      > [role='combobox'] {
        height: 100%;
      }
    }

    .filtersButton {
      display: flex;
      width: 100%;
      height: 100%;
      padding: 0;
      align-items: center;
      cursor: pointer;
      outline: none;
      border-bottom: solid 1px $border-color;

      .leftColumn {
        width: 50px;
        text-align: center;
      }

      .moreIcon {
        color: $white;

        @include inShipTrackerDarkMode {
          color: var(--text-color);
        }

        &.active {
          color: $cerulean;
        }
      }

      > h3 {
        margin: 0;
        font-size: $font-size-regular;
        color: $white;
        text-transform: uppercase;

        @include inShipTrackerDarkMode {
          color: var(--text-color);
        }
      }
    }

    .actionButton {
      display: flex;
      align-items: center;
      justify-content: center;
      outline: none;
      color: $white;
      flex-grow: 0;
      border-left: solid 1px $border-color;
      border-bottom: solid 1px $border-color;
      width: $small-button-width;
      height: 40px;

      @include inShipTrackerDarkMode {
        color: var(--text-color);
      }

      &:hover {
        cursor: pointer;
      }

      &.active {
        background-color: $light-greyish-orange;
        border-bottom-color: transparent;

        @include inShipTrackerDarkMode {
          background-color: var(--tertiary-color);
        }

        .iconSvg {
          background-color: var(--text-color);
        }
      }
      &.disabled {
        pointer-events: none;
        .iconSvg {
          background-color: var(--disabled-color);
        }
      }
      &.borderRight {
        border-right: solid 1px $border-color;
        border-left: none;
      }
    }
  }

  .filtersDropout {
    padding: 0;

    .filtersDropoutContent {
      max-height: calc(100vh - #{$filter-dropout-top-offset});
      display: flex;
      flex-direction: column;
      max-width: 16rem;

      .closeButton {
        @include clickable();
        color: var(--text-color);
        position: absolute;
        top: 1rem;
        right: 1rem;
      }

      .closeButtonIcon {
        font-weight: bold;
        color: var(--text-color);
      }

      @include breakpoint(screen-md, max) {
        width: 100%;
        max-width: 100%;
        max-height: 100vh;
      }

      .filtersList {
        margin-bottom: 0.5rem;
      }

      .filterSection {
        padding: 1.25rem;

        & + .filterSection {
          border-top: 1px solid var(--border-color);
        }

        &Handle {
          @include clickable;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        &Content {
          display: none;

          &.isOpen {
            display: block;
            padding-top: 1.25rem;
            height: auto;
            overflow-y: auto;
            overflow-x: hidden;
          }
        }
      }

      .filterEntry {
        @include spaced-horizontal(1em);
        display: flex;
        align-items: center;
        font-size: $font-size-medium;
        font-family: $font-family-regular;

        .aisVesselStatus {
          flex-shrink: 2;
          margin: 0;
          overflow: hidden;
          &Count {
            flex-grow: 2;
            margin: 0;
            padding-left: 0.5em;
          }

          &Stretch {
            height: 1px;
            overflow: hidden;
            color: transparent;
          }
        }

        & + .filterEntry {
          margin-top: 1.25rem;
        }

        &Disabled {
          pointer-events: none;
          opacity: 0.3;
        }
      }

      .applyButton {
        font-family: $font-family-strong;
        font-size: $font-size-medium;
        @include button($cerulean, $white, $navy-blue, $mostly-pure-yellow);
        box-sizing: border-box;
        width: 100%;

        &:disabled {
          background-color: $navy-blue;
          color: $white;
          opacity: 0.5;
        }
      }
    }
  }

  .listing {
    display: flex;
    position: relative;
    flex-grow: 1;

    .listingInner {
      flex-grow: 1;
    }

    .separator {
      height: 1rem;
      background-color: var(--border-color);
      border: none;
      margin: 0;
    }

    input {
      outline: none;
      @include visuallyHidden;
    }

    .item {
      display: grid;
      grid-template-columns: 1fr 1fr $small-button-width $small-button-width;
      cursor: pointer;

      &.highlighted {
        background-color: rgba($cerulean, 0.1);
      }

      .innerItem {
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-bottom: solid 1px var(--border-color);
        border-left: solid 1px var(--border-color);
        box-sizing: border-box;

        &:first-child {
          border-left: none;
        }

        &.handPickedItem {
          position: relative;
          padding-left: 2rem;

          .closeButton {
            @include clickable;
            position: absolute;
            top: (10/16) * 1rem;
            left: (10/16) * 1rem;
          }
        }

        &Info {
          overflow: hidden;
          padding: 1rem;
          align-items: flex-start;
        }
        &Action {
          padding: 0;
          margin: 0;
          align-items: center;
          cursor: pointer;

          &.disabled {
            > .iconSvg {
              background-color: rgba($greyish-orange, 0.5);
            }
          }
        }

        .label {
          display: inline;
          min-width: 50px;
          padding: 2px 4px;
          border-radius: 2px;
          background-color: $cerulean;
          color: $white;
          text-transform: uppercase;
          font-size: 0.55em;
          margin-bottom: 4px;
          text-align: center;

          &.alongside {
            background-color: $mostly-pure-pink;
          }
          &.anchored {
            background-color: $blue-lagoon;
          }
          &.expected {
            background-color: $cerulean;
          }
          &.inbound {
            background-color: $grape-color;
          }
          &.leftport {
            background-color: $pale-sky;
          }
          &.notavailable {
            background-color: $pale-sky;
          }
          &.outbound {
            background-color: $sahara;
          }
          &.shifting {
            background-color: $sandal;
          }
        }

        .text {
          display: block;
          width: 100%;
          text-align: left;
          font-size: $font-size-regular;

          &Destination {
            font-size: $font-size-tiny;
            color: $cerulean;
          }

          &.etaLabel {
            font-family: $font-family-regular;
          }
        }
      }
    }
  }

  .iconSvg {
    background-color: $cerulean;

    &.light {
      background-color: $white;
    }
    &.mail {
      width: 16px;
      height: 13px;
      mask-image: url('./icons/mail.svg');
      mask-repeat: no-repeat;
      mask-position: center;
      mask-size: 100%;

      &.checked {
        mask-image: url('./icons/mail.checked.svg');
      }
    }
    &.fav {
      width: 14px;
      height: 14px;
      mask-image: url('./icons/fav.svg');
      mask-repeat: no-repeat;
      mask-position: center;
      mask-size: 100%;

      &.checked {
        mask-image: url('./icons/fav.checked.svg');
      }
    }
    &.search {
      width: 18px;
      height: 18px;
      mask-image: url('./icons/search.svg');
      mask-repeat: no-repeat;
      mask-position: center;
      mask-size: 100%;
    }
    &.deleteVessel {
      width: 12px;
      height: 12px;
      background-color: transparent;
      background-image: url('./icons/delete_vessel.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100%;

      &:hover {
        background-image: url('./icons/delete_vessel.hovered.svg');
      }
    }
  }
}
