@import '../../styles/variables';

.toastItem {
  font-size: $font-size-medium;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: $font-family-regular;
  color: $darkgrey;

  .content {
    display: flex;
    flex-direction: column;
  }

  .strong {
    font-family: $font-family-strong;
    color: $navy-blue;
    padding-right: 0.5em;
  }
}

.toastIcon {
  font-size: 20px;
  padding-right: 0.75em;

  &.info {
    color: $cerulean;
  }

  &.error {
    color: $warning-color;
  }

  &.success {
    color: $moderate-lime-green;
  }
}
