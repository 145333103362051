@import '../../styles/z-indices';

.dropoutMenuWrapper {
  width: 100%;
  height: 100%;
}

.dropoutMenu {
  position: relative;
  width: 100%;
  height: 100%;

  &.active {
    z-index: $dropDownMenuIndex;
  }
}

.dropoutMenuOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(#000, 0.3);
  z-index: $dropDownMenuIndex - 1;
}
