@import '../../styles/variables';
@import '../../styles/z-indices';
@import '../../styles/mixins';

$cookieBarHeight: 85px;

.cookieBar {
  background-color: $mostly-pure-yellow;
  padding: 0 3.5rem 0 1rem;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: $cookieBarHeight;
  z-index: $cookieBarIndex;

  display: flex;
  align-items: center;
  justify-content: center;

  transition-property: all;
  transition-duration: 0.5s;

  font-size: $font-size-large;
  color: $navy-blue;

  .closeButton {
    @include clickable();

    position: absolute;
    right: 0;
    top: 0;

    background-color: $catalina-blue;
    width: 2rem;
    height: 2rem;
    padding: 0;
  }

  .closeButtonIcon {
    font-size: 1.3rem;
    font-weight: bold;
    color: white;
  }

  a {
    color: $navy-blue;
    text-decoration: underline;
    margin-left: 0.3rem;
  }
}
